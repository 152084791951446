import React from 'react';
import './ImageModal.css';
import { Image } from 'cloudinary-react';

const ImageModal = ({ imageId, setShowModal, setImageId }) => {
  const handleCloseModalClick = () => {
    setShowModal(false);
    setImageId('');
    document.body.style.overflow = 'visible';
  };

  return (
    <div className="modal--wrapper">
      <img onClick={handleCloseModalClick} className="modal--close" src="./close.svg" alt="close"></img>
      {imageId.includes('poohbear_blog') ? (
        <Image className="modal--image" cloudName="dkugclpc4" publicId={imageId}></Image>
      ) : (
        <img className="modal--image" src={imageId} alt="" />
      )}
    </div>
  );
};

export default ImageModal;
