import React, { useRef, useState } from 'react';
import './Post.css';
import moment from 'moment';
import { Image } from 'cloudinary-react';
import { useIsOverflow } from '../../../utlities/useIsOverflow';
import { ImageModal } from '../ImageModal';
import { OptionModal } from './OptionModal';
import useAuth from '../../../utlities/useAuth';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%ssecs',
    s: 'few secs',
    ss: '%d secs',
    m: '1min',
    mm: '%dmins',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy'
  }
});

const Post = ({ username, timePosted, text, images, id, posts, setPosts }) => {
  const { auth } = useAuth();
  const bodyRef = useRef();
  const isOverflow = useIsOverflow(bodyRef);
  const [showText, setShowText] = useState('Show More...');
  const [showModal, setShowModal] = useState(false);
  const [optionType, setOptionType] = useState('');
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [imageId, setImageId] = useState('');

  const handleClick = (ref) => {
    if (!ref.current.style.maxHeight || ref.current.style.maxHeight === '300px') {
      ref.current.style.maxHeight = 'none';
      setShowText('Show Less...');
    } else {
      ref.current.style.maxHeight = '300px';
      setShowText('Show More...');
    }
  };

  const handleImageClick = (image) => {
    if (showModal) {
      return;
    }

    setImageId(image);
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleMenuOptions = (e) => {
    if (e.target.className.includes('header-menu')) {
      setShowOptionsMenu(!showOptionsMenu);
    }
  };

  const handleOptionMouseOver = (e) => {
    e.target.style.backgroundColor = 'var(--prim-clr)';
  };

  const handleOptionMouseOut = (e) => {
    e.target.style.backgroundColor = 'var(--sec-bkg-clr)';
  };

  const handleOptionModal = (type) => {
    setShowOptionsMenu(!showOptionsMenu);
    setShowOptionModal(true);
    setOptionType(type);
    console.log(optionType, showOptionModal);
  };

  return (
    <div className="post">
      {showOptionModal && (
        <OptionModal
          setShowOptionModal={setShowOptionModal}
          setOptionType={setOptionType}
          optionType={optionType}
          posts={posts}
          setPosts={setPosts}
          data={{ timePosted, username, text, images, id }}
        ></OptionModal>
      )}
      {showModal && <ImageModal imageId={imageId} setShowModal={setShowModal} setImageId={setImageId}></ImageModal>}
      <div className="post-header">
        <section className="header-left">
          <img className="header-pfp" src={username + '.jpg'} alt="profile avatar" />
          <p className="header-username">{username}</p>
        </section>
        <section className="header-right">
          <p className="header-posted-time">{moment(timePosted).fromNow(true)}</p>
          <span onClick={handleMenuOptions} className="header-menu material-icons material-symbols-rounded">
            menu
            <div style={showOptionsMenu ? { display: 'flex' } : { display: 'none' }} className="header-options">
              {auth.username === 'papasbearadmin' && (
                <span
                  onMouseOver={handleOptionMouseOver}
                  onMouseOut={handleOptionMouseOut}
                  onClick={() => handleOptionModal('delete')}
                  className="options-widget material-symbols-outlined"
                >
                  delete_forever
                </span>
              )}
              {auth.username === 'papasbearadmin' && (
                <span
                  onMouseOver={handleOptionMouseOver}
                  onMouseOut={handleOptionMouseOut}
                  onClick={() => handleOptionModal('edit')}
                  className="options-widget material-symbols-outlined"
                >
                  edit
                </span>
              )}
              <span
                onMouseOver={handleOptionMouseOver}
                onMouseOut={handleOptionMouseOut}
                onClick={() => handleOptionModal('info')}
                className="options-widget material-symbols-outlined"
              >
                info
              </span>
            </div>
          </span>
        </section>
      </div>
      <div className="post-body" ref={bodyRef}>
        <p className={images.length ? 'post-text text-images' : 'post-text'}>{text}</p>
        {images.map((image, index) => (
          <Image
            onClick={() => handleImageClick(image)}
            className="post-image"
            key={index}
            cloudName="dkugclpc4"
            publicId={image}
          />
        ))}
      </div>
      <div className="post-footer">
        {isOverflow && (
          <button className="post-show" onClick={() => handleClick(bodyRef)}>
            {showText}
          </button>
        )}
      </div>
    </div>
  );
};

export default Post;
