import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../utlities/useAuth';
import { Navigation } from './Navigation';

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  console.log(auth);
  return auth?.username ? (
    <>
      <Navigation />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
