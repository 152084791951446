import React from "react";
import "./BabyPoohBears.css";

const BabyPoohBears = () => {
  const data = [
    {
      username: "hondacivicbear",
      birthdate: "march 10, 2022",
      about:
        "i join papa on adventures around the world! he takes me to yummy restaurants, gym times, and to see mommy. i protect papa's car from intruders because i'm a scary bear!!!",
    },
    {
      username: "papasbear",
      birthdate: "march 31, 2022",
      about:
        "i love surprising mommy with my cuteness when papa and mommy are video calling! i can't wait until we all move in together and be a big happy family. papa cuddles so much at night time and sometimes accidentally squishes me :( but it okay it was accident!",
    },
    {
      username: "mamasbear",
      birthdate: "april 6, 2022",
      about:
        "my favorite memory is surprising papa on his birthday and getting beignets together! i also have fun watching tv while hugging mommy. she always shares her foodies.i hope i can visit Disneyland next time!",
    },
  ];
  return (
    <div className="bears">
      {data.map((bear) => (
        <div key={bear.username} className="bears-card">
          <img
            className="bears-card--pfp"
            src={"./" + bear.username + ".jpg"}
            alt="pfp of bear"
          />
          <h3 className="bears-card--username">{bear.username}</h3>
          <h3 className="bears-card--birthdate">{bear.birthdate}</h3>
          <h4 className="bears-card--about">{bear.about}</h4>
        </div>
      ))}
    </div>
  );
};

export default BabyPoohBears;
