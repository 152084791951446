import React, { useEffect, useRef, useState } from 'react';
import './Login.css';
import useAuth from '../../utlities/useAuth';
import axios from '../../api';
import { useNavigate, useLocation } from 'react-router-dom';
const LOGIN_URL = '/auth/login';

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const passwordRef = useRef();
  const errorRef = useRef();

  // const username = 'papasbearadmin';
  const username = 'mamasbear';

  // if we want to add more users use below
  // const [username, setUsername] = useState('mamasbear');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('test');

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMessage('');
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify({ username, password }), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
      const accessToken = response?.data?.accessToken;
      setAuth({ username, accessToken });
      setPassword('');
      navigate(from, { replace: true });
    } catch (error) {
      if (!error.response) {
        setErrorMessage('No Error Message');
      } else if (error.response?.status === 400) {
        setErrorMessage('Missing Password');
      } else if (error.response?.status === 401) {
        setErrorMessage('Unauthorized');
      } else {
        setErrorMessage('Login Failed');
      }
      errorRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('persist', persist);
  }, [persist]);

  return (
    <section className="login">
      <p ref={errorRef} className={errorMessage ? 'error-message' : 'error-message empty'} aria-live="assertive">
        {errorMessage || 'Trying again...'}
      </p>
      <img className="login-icon" src="./pooh.svg" alt="baby pooh bear" />
      <h1 className="login-title">Are you mama, papa, or one of the baby pooh bears?</h1>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="login-password">
          <label className="login-password-label" htmlFor="password"></label>
          <input
            className="login-password-input"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="on"
            ref={passwordRef}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          {!showPassword ? (
            <span onClick={handleShowPassword} class="login-vision material-icons material-symbols-outlined">
              visibility
            </span>
          ) : (
            <span onClick={handleShowPassword} class="login-vision material-icons material-symbols-outlined">
              visibility_off
            </span>
          )}
        </div>
        <button className="login-button">LOGIN</button>
        <div className="login-persist">
          <input
            className="login-persist-check"
            type="checkbox"
            id="persist"
            onChange={togglePersist}
            checked={persist}
          />
          <label className="login-persist-text" htmlFor="persist">
            Trust this device?
          </label>
        </div>
      </form>
    </section>
  );
};

export default Login;
