import React, { useState } from 'react';
import './Navigation.css';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Navigation = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(max-width: 740px)'
  });

  const onMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div className="navigation" style={{ backgroundColor: openMenu && 'var(--sec-bkg-clr)' }}>
      {isDesktopOrLaptop && (
        <span
          style={{ backgroundColor: openMenu && 'var(--prim-clr)' }}
          className="navigation-menu-button material-icons material-symbols-rounded"
          onClick={onMenuClick}
        >
          menu
        </span>
      )}
      <nav className="navigation-links">
        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/"
        >
          <img src="./home.svg" alt="Home" />
        </NavLink>

        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/posts"
        >
          <img src="./pen.svg" alt="All Posts" />
        </NavLink>
        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/family"
        >
          <img src="./heart.svg" alt="Our Family" />
        </NavLink>
        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/babypoohbears"
        >
          <img src="./pooh.svg" alt="Baby Pooh Bears" />
        </NavLink>
        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/gallery"
        >
          <img src="./gallery.svg" alt="Gallery" />
        </NavLink>
        <NavLink
          style={{
            display: !isDesktopOrLaptop || openMenu ? 'inline' : 'none'
          }}
          className={({ isActive }) => (isActive ? 'active navigation-link' : 'navigation-link')}
          to="/movies"
        >
          <img src="./movies.svg" alt="Movies" />
        </NavLink>
      </nav>
    </div>
  );
};

export default Navigation;
