import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <img
        className="home-image"
        src="./home-bkg-img.jpg"
        alt="Three baby pooh bears relaxing at the beach!"
      />
      <div className="home-heading">
        <h1 className="home-title">life is sweet as honey!</h1>
        <h2 className="home-title--caption">by babypoohbears</h2>
      </div>
    </div>
  );
};

export default Home;
