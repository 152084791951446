import React, { useEffect, useState } from 'react';
import './Posts.css';
import { Form } from './Form';
import { Post } from './Post';
import useAxiosPrivate from '../../utlities/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const POSTPAGE_URL = '/post/page';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [morePosts, setMorePosts] = useState(true);
  const [id, setId] = useState(2147483647);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const getPosts = async () => {
    try {
      const response = await axiosPrivate.get(`${POSTPAGE_URL}/${id}`);
      if (!response.data.length) {
        setMorePosts(!morePosts);
      } else {
        response.data.reverse();
        setId(response.data[response.data.length - 1].id);
        setPosts([...posts, ...response.data]);
      }
    } catch (error) {
      console.log(error);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get(`${POSTPAGE_URL}/${id}`);
        if (!response.data.length) {
          setMorePosts(!morePosts);
        } else {
          response.data.reverse();
          setId(response.data[response.data.length - 1].id);
          setPosts([...posts, ...response.data]);
        }
      } catch (error) {
        console.log(error);
        navigate('/login', { state: { from: location }, replace: true });
      }
    };
    getPosts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="posts">
      <Form setPosts={setPosts} posts={posts}></Form>
      <InfiniteScroll
        dataLength={posts.length} //This is important field to render the next data
        next={getPosts}
        hasMore={morePosts}
        loader={
          <div className="lds-heart">
            <div></div>
          </div>
        }
        endMessage={
          <p className="posts-end-message">
            <b>Yay! You've reached the end of baby pooh bear blog!</b>
          </p>
        }
      >
        {posts.map((post) => (
          <Post
            key={post.id}
            username={post.username}
            timePosted={post.time_posted}
            text={post.text}
            images={post.images}
            id={post.id}
            posts={posts}
            setPosts={setPosts}
          ></Post>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Posts;
