import './App.css';
import { Login } from './components/Login';
import { Home } from './components/Home';
import { Posts } from './components/Posts';
import { Family } from './components/Family';
import { BabyPoohBears } from './components/BabyPoohBears';
import { Missing } from './components/Missing';
import { Gallery } from './components/Gallery';
import { Movies } from './components/Movies';
import { Layout } from './components/Layout';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />

          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Home />} />
              <Route path="/posts" element={<Posts />} />
              <Route path="/family" element={<Family />} />
              <Route path="/babypoohbears" element={<BabyPoohBears />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/movies" element={<Movies />} />
              <Route path="*" element={<Missing />} />
            </Route>
          </Route>
        </Route>
        {/*  */}
        {/* 
        
        */}
      </Routes>
    </div>
  );
}

export default App;
