import React from "react";
import "./Family.css";

const Family = () => {
  const caption =
    "we were adopted by papa and mommy in 2022! we’ve already been on so many adventures to Disneyland and Balboa Pier! our favorite foods are beignets and Pepper Lunch! we love our family so much <3";
  return (
    <div className="family">
      <div className="family-heading--container">
        <h1 className="family-heading">our family</h1>
        <h3 className="family-heading--caption">{caption}</h3>
      </div>
      <img
        className="family-img"
        src="./ourfamily.jpg"
        alt="our family eating pepperlunch! mama took it!"
      />
    </div>
  );
};

export default Family;
