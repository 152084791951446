import React, { useEffect, useState } from 'react';
import './Form.css';
import { ImagePreview } from './ImagePreview';
import { ImageModal } from '../ImageModal';
import useAxiosPrivate from '../../../utlities/useAxiosPrivate';

const POST_URL = '/post';
const GET_RECENT_POST_URL = '/post/recent';
const imageTypeRegex = /image\/(png|jpg|jpeg|heic)/gm;

const Form = ({ setPosts, posts }) => {
  const options = ['mamasbear', 'papasbear', 'hondacivicbear'];
  const axiosPrivate = useAxiosPrivate();
  const [imageFiles, setImageFiles] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageId, setImageId] = useState('');
  const [formData, setFormData] = useState({
    username: 'mamasbear',
    text: '',
    images: []
  });

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, 250)}px`;
  };

  const handleImageClick = (image) => {
    if (showModal) {
      return;
    }

    setImageId(image);
    setShowModal(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseClick = (index) => {
    setImageFiles(imageFiles.filter((imageFile, i) => i !== index));
    setFormData({
      ...formData,
      images: formData.images.filter((image, i) => i !== index)
    });
  };

  const handleImagesChange = (e) => {
    setLoadingImage(true);
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setImageFiles([...imageFiles, ...validImageFiles]);
      return;
    }
    alert('Selected images are not of valid type!');
    setLoadingImage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loadingSubmit) {
      return;
    }
    setLoadingSubmit(true);
    if (!formData.text && formData.images.length === 0) {
      alert('Post something for pooh bear!');
      setLoadingSubmit(false);
      return;
    }

    try {
      await axiosPrivate.post(POST_URL, formData);
      setFormData({
        ...formData,
        text: '',
        images: []
      });
      setImageFiles([]);
      setLoadingSubmit(false);
      try {
        const response = await axiosPrivate.get(GET_RECENT_POST_URL);
        setPosts([...response.data, ...posts]);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      const promises = imageFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReaders.push(fileReader);
          fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
              resolve(result);
            }
          };
          fileReader.onabort = () => {
            reject(new Error('File reading aborted'));
          };
          fileReader.onerror = () => {
            reject(new Error('Failed to read file'));
          };
          fileReader.readAsDataURL(file);
        });
      });
      Promise.all(promises)
        .then((images) => {
          if (!isCancel) {
            setFormData({ ...formData, images: images });
            setLoadingImage(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
    // eslint-disable-next-line
  }, [imageFiles]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      {showModal && <ImageModal imageId={imageId} setShowModal={setShowModal} setImageId={setImageId}></ImageModal>}

      <div className="form-header">
        <img src={formData.username + '.jpg'} alt="pooh bear with mama or papa" className="form-pfp" />
        <select
          className="form-username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="form-body">
        {loadingSubmit && (
          <div className="lds-heart">
            <div></div>
          </div>
        )}
        <textarea
          className={formData.images.length ? 'form-textarea form-textarea-images' : 'form-textarea'}
          placeholder="Write your adventures here..."
          value={formData.text}
          onChange={(e) => setFormData({ ...formData, text: e.target.value })}
          onKeyDown={handleKeyDown}
        ></textarea>
        {formData.images.length > 0 && (
          <div className="form-textarea-previewimages">
            {loadingImage && (
              <div className="lds-heart">
                <div></div>
              </div>
            )}
            {formData.images.map((image, index) => (
              <ImagePreview
                key={index}
                index={index}
                image={image}
                handleCloseClick={handleCloseClick}
                handleImageClick={handleImageClick}
              ></ImagePreview>
            ))}
          </div>
        )}
      </div>
      <div className="form-footer">
        <label disabled={loadingSubmit ? 'disabled' : false} className="form-images">
          <img className="form-icon" src="./camera.svg" alt="upload images" />
          <input
            disabled={loadingSubmit ? 'disabled' : false}
            type="file"
            accept="image/png, image/jpg, image/jpeg, image/heic"
            multiple="multiple"
            onChange={handleImagesChange}
          />
        </label>
        <button disabled={loadingSubmit ? 'disabled' : false} className="form-submit" type="submit">
          Post!
        </button>
      </div>
    </form>
  );
};

export default Form;
