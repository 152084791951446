import React from 'react';
import './OptionModal.css';
import moment from 'moment';
import useAxiosPrivate from '../../../../utlities/useAxiosPrivate';

const DELETE_POST_URL = '/post';

const OptionModal = ({ setShowOptionModal, setOptionType, optionType, posts, setPosts, data }) => {
  const axiosPrivate = useAxiosPrivate();

  const handleCloseModalClick = () => {
    setShowOptionModal(false);
    setOptionType('');
    document.body.style.overflow = 'visible';
  };

  const handleDeletePostClick = async () => {
    const response = await axiosPrivate.delete(`${DELETE_POST_URL}/${data.id}`);

    setPosts(posts.filter((post) => post.id !== parseInt(response.data)));
    handleCloseModalClick();
  };

  const handleSavePostClick = () => {
    console.log('SAVE');
    handleCloseModalClick();
  };

  return (
    <div className="option-modal">
      <div className="option-base">
        {optionType === 'delete' && (
          <>
            <div className="option-header">
              <h2>Delete</h2>
            </div>
            <div className="option-body">
              <h4>Are you sure you want to delete this post?</h4>
            </div>
            <div className="option-footer">
              <button onClick={handleDeletePostClick} className="option-button delete">
                Delete
              </button>
              <button onClick={handleCloseModalClick} className="option-button">
                Close
              </button>
            </div>
          </>
        )}
        {optionType === 'edit' && (
          <>
            <div className="option-header">
              <h2>Edit</h2>
            </div>
            <div className="option-body">
              <h5>Username: {data.username}</h5>
              <h5>Date Posted: {moment(data.timePosted).format('DD-MM-YYYY')}</h5>
            </div>
            <div className="option-footer">
              <button onClick={handleSavePostClick} className="option-button save">
                Save
              </button>
              <button onClick={handleCloseModalClick} className="option-button">
                Close
              </button>
            </div>
          </>
        )}
        {optionType === 'info' && (
          <>
            <div className="option-header">
              <h2>Info</h2>
            </div>
            <div className="option-body">
              <h5>Username: {data.username}</h5>
              <h5>Date Posted: {moment(data.timePosted).format('DD-MM-YYYY')}</h5>
            </div>
            <div className="option-footer">
              <button onClick={handleCloseModalClick} className="option-button">
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OptionModal;
