import React from 'react';
import './ImagePreview.css';

const ImagePreview = ({ handleCloseClick, handleImageClick, image, index }) => {
  return (
    <div className="imagepreview--wrapper">
      <img
        onClick={() => {
          handleCloseClick(index);
        }}
        className="imagepreview--close"
        src="./close.svg"
        alt="close"
      />
      <img className="imagepreview" src={image} onClick={() => handleImageClick(image)} alt="" />
    </div>
  );
};

export default ImagePreview;
